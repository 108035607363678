import React from 'react';
import { FaQuestionCircle, FaInfoCircle, FaRegFileAlt } from 'react-icons/fa';

const SectionCard = ({ title, children, icon }) => (
  <div className="bg-bgcolor3 rounded-lg shadow-lg p-6 mb-8 hover:shadow-xl transition-shadow duration-300 mx-4 md:mx-40 border border-gray-500 border-opacity-10">
    <div className="flex items-center mb-4">
      <div className="text-blue-400 text-3xl mr-3">{icon}</div>
      <h2 className="text-3xl font-bold text-white">{title}</h2>
    </div>
    <div className="text-xl text-gray-300">{children}</div>
  </div>
);

const HelpPage = () => {
  return (
    <div className="py-16 mt-16">
      <div className="container mx-auto">
        <SectionCard title="Welcome to the Help Page!" icon={<FaQuestionCircle />}>
          Here you will find resources and answers to frequently asked questions to make your experience easier.
        </SectionCard>

        <SectionCard title="User Manual - Classify" icon={<FaRegFileAlt />}>
          <p className="text-3xl font-semibold mb-2">Software Installation</p>
          <p className="text-xl mb-8">
            1. Download the installer from our official website.<br />
            2. Run the downloaded file and follow the on-screen instructions.<br />
            3. Once the installation is complete, open Classify from the applications menu.
          </p>

          <p className="text-3xl font-semibold mb-2">Use of the Application</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Register" button.<br />
            3. Complete the name, email, and password fields, then click "Register."
          </p>

          <p className="text-3xl font-semibold mb-2">Register</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Register" button.<br />
            3. Enter your name, email, and password, then click "Register."
          </p>

          <p className="text-3xl font-semibold mb-2">Login</p>
          <p className="text-xl mb-8">
            1. Open Classify.<br />
            2. Click the "Login" button.<br />
            3. Enter your email and password, then click "Login."
          </p>

          <p className="text-3xl font-semibold mb-2">File Organization</p>
          <p className="text-xl mb-8">
            1. Click the "Browse" button to select a folder.<br />
            2. Click "Organize" to sort the files by type.
          </p>

          <p className="text-3xl font-semibold mb-2">Software Update</p>
          <p className="text-xl mb-8">
            Classify checks for updates automatically. When an update is available, follow the on-screen instructions to update and do not close the program until it finishes.
          </p>

          <p className="text-3xl font-semibold mb-2">Troubleshooting</p>
          <p className="text-xl mb-8">
            <strong>Error registering:</strong> Make sure all fields are complete and correct.<br />
            <strong>Login error:</strong> Please verify your email and password.<br />
            If the problems persist then contact support: <b className='break-all'>support@bushcoding.freshdesk.com</b>
          </p>

          <p className="text-3xl font-semibold mb-2">Support and Contact</p>
          <p className="text-xl mb-8">
            For technical support, contact our team at <b className='break-all'>support@bushcoding.freshdesk.com</b>.
          </p>
        </SectionCard>
      </div>
    </div>
  );
};

export default HelpPage;
